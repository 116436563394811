<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
            Cancelamento
          </h2>
        </div>
      </vs-row>

      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>RA do Aluno <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="RA"
                  rules="required|numeric"
                   
                >
                  <vs-input
                    @keyup.enter="getCancellationByRA()" 
                    v-model="ra"
                    placeholder="Digite o RA do Aluno"
                    class="selectExampleRA"
                  >
                  </vs-input>
                   
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getCancellationByRA()"
                >
                  <font-awesome-icon :icon="['fas', 'search']" />
                  <span> Buscar </span></vs-button
                >
              </fieldset>              
            </vs-col>
          </vs-row>          
        </ValidationObserver>
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div   v-if="responseRa != null">    
          <div v-if="responseRa == ''">
            <p class="validacao">RA Inválido</p>              
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Nome do Aluno</legend>
                  <vs-input
                    class="selectExampleDouble"
                    :placeholder="responseRa[0].NOMEALUNO"
                    disabled="disabled"
                  />
                </fieldset>                    
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Responsável Financeiro <b style="color: red;">*</b></legend>
                  <vs-input
                    class="selectExampleDouble"
                    :placeholder="responseRa[0].RESPONSAVELFINANCEIRO"
                    disabled="disabled"
                  />
                </fieldset>                  
              </vs-col>
            </vs-row> 

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Email Responsável Financeiro <b style="color: red;">*</b></legend>
                  <vs-input
                    class="selectExample"
                    :placeholder="responseRa[0].EMAILRESPONSAVEL"
                    disabled="disabled"
                  />
                </fieldset>
              </vs-col>
            </vs-row>  

            <vs-row class="pd-table">
              <vs-col>
                <fieldset>
                  <legend>Selecione um Curso <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Curso"
                    rules="required"
                     
                  >
                    <vs-table              
                      class="table_intranet"
                      hoverFlat
                      v-model="selected"
                      :data="responseRa"
                      v-on:input="selectedItens()"                        
                    >
                      <template slot="thead">
                        <vs-th> Curso </vs-th>
                        <vs-th> Filial </vs-th>
                        <vs-th> Polo </vs-th>
                      </template>

                      <template slot-scope="{data}">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td>
                            {{ data[indextr].CURSO }}
                          </vs-td>

                          <vs-td>
                            {{ data[indextr].NOMEFILIAL }}
                          </vs-td>

                          <vs-td v-if="data[indextr].POLO == '[object Object]'">                                          
                          </vs-td>

                          <vs-td v-else>
                            {{ data[indextr].POLO }}
                          </vs-td>                             
                        </vs-tr>
                      </template>
                    </vs-table>
                     
                  </ValidationProvider>
                </fieldset>                    
              </vs-col>  
            </vs-row>              

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Curso Selecionado <b style="color: red;">*</b></legend>
                  <vs-input
                    class="selectExample"
                    :placeholder="courseSelected"
                    disabled="disabled"
                  />
                </fieldset>                                    
              </vs-col>
            </vs-row>  

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Filial/Polo</legend>
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12" >
                    <vs-input
                      class="selectExampleDouble"                      
                      :placeholder="idBranchSelected"
                      disabled="disabled"
                    />
                  </vs-col>                                      

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12" >
                    <vs-input
                      class="selectExampleDouble"                        
                      :placeholder="poloSelected"
                      disabled="disabled"
                    />
                  </vs-col>
                </fieldset>
              </vs-col>                                
            </vs-row>               

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                      rows="11"
                      label="Justificativa *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>          
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>  
</template>

<script>
import Students from "../../../../services/students";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  name: "Cancelamento",
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
    selected: [],
    courseSelected: null,
    idBranchSelected: null,
    poloSelected: null,
  }),

  methods: {
    getCancellationByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCancelDataByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },

    selectedItens() {
      this.courseSelected = this.selected.CURSO;
      this.idBranchSelected = this.selected.NOMEFILIAL;
      this.poloSelected = this.selected.POLO == "[object Object]" ? this.selected.FILIAL : this.selected.POLO;
    },
  
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const studentName = this.responseRa[0].NOMEALUNO;
      const financialResponsibleName = this.responseRa[0].RESPONSAVELFINANCEIRO;
      const responsibleEmailName = this.responseRa[0].EMAILRESPONSAVEL;   

      const payload = new EducacionalModel().getDataByAttendanceId("E5-2");

      payload.data.locationId = this.selected.CODCAMPUS ? this.selected.CODCAMPUS : this.selected.CODFILIAL;
      payload.data.ra = this.ra;
      payload.data.codCurso = this.responseRa[0].CODCURSO;
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA DO ALUNO: ${this.ra}\nALUNO: ${studentName}\nRESPONSÁVEL FINANCEIRO: ${financialResponsibleName}\nEMAIL RESPONSÁVEL FINANCEIRO: ${responsibleEmailName}\nCURSO: ${this.courseSelected}\nFILIAL: ${this.idBranchSelected}\nPOLO: ${this.poloSelected}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.poloSelected = null;
      this.courseSelected = null;
      this.selected = null;
      this.idBranchSelected = null;
      this.solicitation = null; 
      this.responseRa = null;  
      this.ra = null;   

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>

