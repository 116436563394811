import { render, staticRenderFns } from "./ComunicacaoDeSubstituicaoDeProfessorInstrutor.vue?vue&type=template&id=73e26476&scoped=true"
import script from "./ComunicacaoDeSubstituicaoDeProfessorInstrutor.vue?vue&type=script&lang=js"
export * from "./ComunicacaoDeSubstituicaoDeProfessorInstrutor.vue?vue&type=script&lang=js"
import style0 from "./ComunicacaoDeSubstituicaoDeProfessorInstrutor.vue?vue&type=style&index=0&id=73e26476&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e26476",
  null
  
)

export default component.exports