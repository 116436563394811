<template>
  <div class="subContainer">
    <vs-row vs-w="12" class="topo">
      <div class="titulo">
        <font-awesome-icon :icon="['fas', 'user-plus']" />
        <h2 class="tituloAtendimento">Aumento de Quadro</h2>
      </div>
    </vs-row>

    <div>
      <vs-row class="pt-d">
        <vs-col>
          <Message class="pd-t"
            :msg="idAttendance"
            :error="errorIdAttendance"
            v-if="idAttendance || errorIdAttendance"
          />
        </vs-col>
      </vs-row>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Nome Indicação</legend>
              <ValidationProvider name="Nome Indicação">
                <vs-input
                  v-model="nameIndication"
                  placeholder="Digite o Nome da Indicação"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Telefone Indicação</legend>
              <ValidationProvider
                name="Telefone Indicação"
                rules="min:15"
              >
                <vs-input
                  v-model="phoneIndication"
                  placeholder="(00) 00000-0000"
                  v-mask="'(##) #####-####'"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
              <b style="font-size: 0.85em">Formato: (00) 00000-0000</b>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Filial Destino <b style="color: red">*</b></legend>
              <ValidationProvider name="Filial Destino" rules="required">
                <vs-select
                  placeholder="Selecione a Filial de Destino"
                  v-model="selectedUnit"
                  class="selectExampleDouble"
                  v-on:input="getJobsUnits()"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.CODFILIAL"
                    :text="item.POLO"
                    v-for="(item, index) in data.units"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Vaga <b style="color: red">*</b></legend>
              <ValidationProvider name="Vaga" rules="required">
                <vs-select
                  autocomplete
                  placeholder="Selecione a Vaga"
                  v-model="vacancyFrameIncrease"
                  @input-change="inputChange"
                  class="selectExampleDouble"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.FUNCAO"
                    :text="item.FUNCAO"
                    v-for="(item, index) in data.JobsFilial"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Tipo Contratação <b style="color: red">*</b></legend>
              <ValidationProvider name="Tipo Contratação" rules="required">
                <vs-select
                  class="selectExampleDouble"
                  placeholder="Selecione o Tipo de Contratação"
                  v-model="indexSelectedHiring"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.typeHiring"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Data Início <b style="color: red">*</b></legend>
              <ValidationProvider name="Data Início" rules="required">
                <vs-input
                  type="date"
                  v-model="startDate"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Salário <b style="color: red">*</b></legend>
              <ValidationProvider name="Salário" rules="required">
                <vs-input
                  v-model="salary"
                  placeholder="Digite o Salário"
                  class="selectExampleDouble"
                  type="number"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Tipo Salário <b style="color: red">*</b></legend>
              <ValidationProvider name="Tipo Salário" rules="required">
                <vs-select
                  class="selectExampleDouble"
                  placeholder="Selecione o Tipo de Salário"
                  v-model="indexSelectedSalary"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.typeSalary"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Horário <b style="color: red">*</b></legend>
              <ValidationProvider name="Horário" rules="required">
                <div class="horario">
                  <vs-input
                    class="input-horario"
                    v-model="time[0]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[1]"
                    v-mask="'##:##'"
                  />
                  <span>e</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[2]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[3]"
                    v-mask="'##:##'"
                  />
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Turno <b style="color: red">*</b></legend>
              <ValidationProvider name="Turno" rules="required">
                <span v-for="(shifts, index) in selectedShift" :key="index">
                  <input
                    type="checkbox"
                    :id="index"
                    class="checkboxTurn"
                    v-model="shift"
                    :value="shifts.value"
                  /><label :for="index">{{ shifts.value }}</label>
                </span>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Dias <b style="color: red">*</b></legend>
              <ValidationProvider name="Dias" rules="required">
                <div class="boxTurn">
                  <span v-for="(weeks, index) in selectedWeek" :key="index">
                    <input
                      type="checkbox"
                      :id="index"
                      class="checkboxTurn"
                      v-model="week.weekArray"
                      :value="weeks.value"
                    />{{ weeks.value }}
                  </span>
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Sábados <b style="color: red">*</b></legend>
              <ValidationProvider name="Sábado" rules="required">
                <vs-select
                  class="selectExample"
                  placeholder="Selecione"
                  v-model="indexSelectedWeekend"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in week.weekend"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col
            class="pd-c"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
            v-if="
              indexSelectedWeekend == 'Todos os Sabados' ||
              indexSelectedWeekend == 'Sábados alternados'
            "
          >
            <fieldset>
              <legend>Horário do Sábado <b style="color: red">*</b></legend>
              <ValidationProvider name="Horário Sábado" rules="required">
                <div class="horario">
                  <vs-input
                    class="input-horario"
                    v-model="timeSaturday[0]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="timeSaturday[1]"
                    v-mask="'##:##'"
                  />
                  <span>e</span>
                  <vs-input
                    class="input-horario"
                    v-model="timeSaturday[2]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="timeSaturday[3]"
                    v-mask="'##:##'"
                  />
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider name="Descrição Vaga" rules="required|max: 500">
              <vs-textarea
                label="Descrição da Vaga *"
                height="210px"
                rows="11"
                class="textArea"
                counter="500"
                v-model="solicitation"
              />
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>

            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>
        </vs-row>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import PessoalModel from "../../../../models/pessoal/pessoal.js";
import {
  getLoginDataFromStorage,
  convertDate,
  poloTextFormat,
} from "../../../../helpers/storage";

export default {
  name: "AumentoQuadro",
  data: () => ({
    typeSelected: null,
    indexSelectedSub: null,
    data: {
      units: null,
      null: null,
      JobsFilial: null,
      errorJobsFilial: null,
      typeHiring: [
        { text: "CLT", value: "CLT" },
        { text: "Estágio", value: "Estágio" },
        { text: "RPA", value: "RPA" },
      ],
      typeSalary: [
        { text: "Mensalista", value: "Mensalista" },
        { text: "Horista", value: "Horista" },
      ],
    },
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
    ],
    week: {
      weekArray: [],
      weekend: [
        { text: "Não trabalha Sábados", value: "Não trabalha Sábados" },
        { text: "Todos os Sábados", value: "Todos os Sabados" },
        { text: "Sábados alternados", value: "Sábados alternados" },
      ],
    },
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    shift: [],
    selectedUnit: null,
    indexSelectedHiring: null,
    indexSelectedSalary: null,
    indexSelectedWeekend: null,
    nameIndication: "",
    phoneIndication: null,
    vacancyFrameIncrease: null,
    startDate: null,
    salary: null,
    time: [],
    timeSaturday: [],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.data.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.null = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      let timeSaturdayText =
        this.indexSelectedWeekend == "Não trabalha Sábados"
          ? "SEM HORÁRIO DE SÁBADO"
          : `HORÁRIO SÁB: ${this.timeSaturday[0]} ATÉ ${this.timeSaturday[1]} E DAS ${this.timeSaturday[2]} ATÉ ${this.timeSaturday[3]}`;

      const payload1 = new PessoalModel().getDataByAttendanceId("P1");

      payload1.data.locationId = personLocalId.toString();
      payload1.data.personId = personId.toString();
      payload1.data.attendantId = attendantId.toString();
      payload1.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Aumento de quadro\nPARA À ESCOLA: ${poloTextFormat(
        this.selectedUnit,
        this.data.units
      )} \nINDICAÇÃO: (Nome: ${this.nameIndication.toUpperCase()} || Contato: ${
        this.phoneIndication
      })\nCONTRATAÇÃO: ${
        this.indexSelectedHiring
      }\nDATA DE INICIO: ${convertDate(this.startDate)}\nTIPO DE SALÁRIO: ${
        this.indexSelectedSalary
      }\nSALÁRIO: R$${this.salary}\nDIAS: ${this.week.weekArray}\nTURNO: ${
        this.shift
      }\nHORÁRIO: ${this.time[0]} ATÉ ${this.time[1]} E DAS ${
        this.time[2]
      } ATÉ ${this.time[3]}\nTRABALHA SÁBADO: ${
        this.indexSelectedWeekend
      }\n${timeSaturdayText}\nAREA DE ATUAÇÃO: ${
        this.vacancyFrameIncrease
      }\nDESCRIÇÃO DA VAGA: ${this.solicitation.toUpperCase()}`;

      const payload2 = new PessoalModel().getDataByAttendanceId("P1-2");

      payload2.data.locationId = personLocalId.toString();
      payload2.data.personId = personId.toString();
      payload2.data.attendantId = attendantId.toString();
      payload2.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Aumento de quadro\nPARA À ESCOLA: ${poloTextFormat(
        this.selectedUnit,
        this.data.units
      )} \nINDICAÇÃO: (Nome: ${this.nameIndication.toUpperCase()} || Contato: ${
        this.phoneIndication
      })\nCONTRATAÇÃO: ${
        this.indexSelectedHiring
      }\nDATA DE INICIO: ${convertDate(this.startDate)}\nTIPO DE SALÁRIO: ${
        this.indexSelectedSalary
      }\nSALÁRIO: R$${this.salary}\nDIAS: ${this.week.weekArray}\nTURNO: ${
        this.shift
      }\nHORÁRIO: ${this.time[0]} ATÉ ${this.time[1]} E DAS ${
        this.time[2]
      } ATÉ ${this.time[3]}\nTRABALHA SÁBADO: ${
        this.indexSelectedWeekend
      }\n${timeSaturdayText}\nAREA DE ATUAÇÃO: ${
        this.vacancyFrameIncrease
      }\nDESCRIÇÃO DA VAGA: ${this.solicitation.toUpperCase()}`;

      let vaga = this.vacancyFrameIncrease.split(" ");
      let payloads =
        vaga[0] == "PROFESSOR(A)" ||
        vaga[0] == "PROF." ||
        vaga[0] == "INSTRUTOR" ||
        vaga[0] == "TUTOR" ||
        vaga[0] == "TUTOR(A)"
          ? payload1.data
          : payload2.data;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payloads)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },
    resetForm() {
      this.indexSelectedHiring = "";
      this.indexSelectedSalary = "";
      this.nameIndication = "";
      this.phoneIndication = null;
      this.startDate = null;
      this.salary = null;
      this.time = [];
      this.currencyMask = null;
      this.selectedUnit = "";
      this.vacancyFrameIncrease = "";
      this.shift = [];
      this.solicitation = null;
      this.week.weekArray = [];
      this.indexSelectedWeekend = null;
      this.timeSaturday = [];

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getJobsUnits() {
      new Units()
        .getJobsUnits(this.selectedUnit)
        .then(({ data }) => {
          this.data.JobsFilial = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.data.errorJobsFilial = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    inputChange(event) {
      return event.target.value;
    },
  },
};
</script>

<style scoped>
.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 580px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2em;
  }

  .boxTurn {
    flex-direction: column;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 440px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
