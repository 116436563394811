export default class {
    constructor() {
        this.instance = 
            [     
                {
                    typeAttendanceId: "E1", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / ABERTURA DE TURMA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00077",
                        subject: "PLANEJAMENTO ACADÊMICO - ABERTURA DE TURMA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-2", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / ADIAMENTO DE TURMA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00081",
                        subject: "PLANEJAMENTO ACADÊMICO - ADIAMENTO DE TURMA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-3", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / AJUSTE DE HORÁRIO/DIAS DA SEMANA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00084",
                        subject: "PLANEJAMENTO ACADÊMICO - AJUSTE DE HORÁRIO/DIAS DA SEMANA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-4", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / ALTERAÇÃO DE PROFESSOR/INSTRUTOR */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00079",
                        subject: "PLANEJAMENTO ACADÊMICO - ALTERAÇÃO DE PROFESSOR/INSTRUTOR",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-5", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / AJUSTE DE VAGAS */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00080",
                        subject: "PLANEJAMENTO ACADÊMICO - AJUSTE DE VAGAS",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-6", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / CRIAÇÃO DE CURSO NOVO */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00083",
                        subject: "PLANEJAMENTO ACADÊMICO - CRIAÇÃO DE CURSO NOVO",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-7", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / FECHAMENTO DE TURMA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00078",
                        subject: "PLANEJAMENTO ACADÊMICO - FECHAMENTO DE TURMA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-8", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / SOLICITAÇÃO DE SEQUÊNCIA DE TURMA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00085",
                        subject: "PLANEJAMENTO ACADÊMICO - SOLICITAÇÃO DE SEQUÊNCIA DE TURMA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-9", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / TROCA DE SALA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00082",
                        subject: "PLANEJAMENTO ACADÊMICO - TROCA DE SALA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-10", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / COMUNICAÇÃO DE TROCA DE PROFESSOR/INSTRUTOR */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00111",
                        subject: "PLANEJAMENTO ACADÊMICO - COMUNICAÇÃO DE TROCA DE PROFESSOR/INSTRUTOR",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E1-11", /* EDUCACIONAL / PLANEJAMENTO ACADEMICO / SOLICITAÇÃO DE REDUÇÃO DE CARGA HORÁRIA */
                    data: {                          

                        respAttendantId: "2933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00112",
                        subject: "PLANEJAMENTO ACADÊMICO - SOLICITAÇÃO DE REDUÇÃO DE CARGA HORÁRIA",
                        accessLevel: "2",
                        typeAttendanceId: "207",
                        groupAttendanceId: "20",
                        personId: "",
                        requestText: "",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E2", /* EDUCACIONAL / REGULAÇÃO ESCOLAR */
                    data: {                          

                        respAttendantId: "4330", 
                        productId: null,
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00092",
                        subject: "Solicitação de REGULAÇÃO ESCOLAR",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E3", /* EDUCACIONAL / SOLICITAÇÕES GRADUAÇÃO EAD */
                    data: {                          

                        respAttendantId: "4288", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        subject: "PROTOCOLO ONLINE - SOLICITAÇÕES GRADUAÇÃO EAD",
                        accessLevel: null,
                        typeAttendanceId: "174",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",                   
                    }
                },

                {
                    typeAttendanceId: "E4", /* EDUCACIONAL / CERTIFICADO / ALUNO DESTAQUE, LÁUREA ACADÊMICA, MONITORIA, PALESTRA, PALESTRANTE, PARCIAL, QI IDEIAS */
                    data: {                          

                        respAttendantId: "4330", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                        
                        subject: "",
                        accessLevel: "0",
                        typeAttendanceId: "21",
                        groupAttendanceId: "3",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E5", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / CERTIFICADO */
                    data: {                          

                        respAttendantId: "4328",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00008",                       
                        subject: "SOLICITAÇÃO DE ALUNO: CERTIFICADO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "165",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",               
                    }
                },

                {
                    typeAttendanceId: "E5-2", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / CANCELAMENTO */
                    data: {                          

                        respAttendantId: "5100",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00066",                       
                        subject: "SOLICITAÇÃO DE ALUNO: CANCELAMENTO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "195",
                        groupAttendanceId: "17",
                        ra: "",
                        codCurso: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E5-3", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / DIPLOMA */
                    data: {                          

                        respAttendantId: "4330",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00005",                       
                        subject: "SOLICITAÇÃO DE ALUNO: DIPLOMA - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "237",
                        groupAttendanceId: "25",
                        ra: "",
                        requestText: "",                 
                        clientType: "Aluno",
                        attendantId: "",     
                    }
                },

                {
                    typeAttendanceId: "E5-4", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / FORMATURA GABINETE, FORMATURA SOLENE, GRADUAÇÃO EAD */
                    data: {                          

                        respAttendantId: "4288", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                       
                        subject: "",
                        accessLevel: null,
                        typeAttendanceId: "174",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",                      
                        clientType: "Aluno",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E5-5", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / NOTA FISCAL */
                    data: {                          

                        respAttendantId: "4701", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00062",                       
                        subject: "SOLICITAÇÃO DE ALUNO: NOTA FISCAL - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "164",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",    
                        clientType: "Aluno",
                        attendantId: "",                  
                    }
                },

                {
                    typeAttendanceId: "E5-6", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / RETORNO DE TRANCAMENTO */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00045",                       
                        subject: "SOLICITAÇÃO DE ALUNO: RETORNO DE TRANCAMENTO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "168",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",
                    }
                },

                {
                    typeAttendanceId: "E5-7", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TERMO DE ESTÁGIO */
                    data: {                          

                        respAttendantId: "4328", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00065",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TERMO DE ESTÁGIO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "169",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",                 
                    }
                },

                {
                    typeAttendanceId: "E5-8", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / OUTRAS SOLICITAÇÕES */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                       
                        subject: "SOLICITAÇÃO DE ALUNO: OUTRAS SOLICITAÇÕES - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "185",
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",
                    }
                },

                {
                    typeAttendanceId: "E5-9", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TRANSFERÊNCIA DE FILIAL/POLO */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00044",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TRANSFERÊNCIA DE FILIAL/POLO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "190",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",                     
                    }
                },

                {
                    typeAttendanceId: "E5-10", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TRANSFERÊNCIA DE TURNO E TURMA */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00044",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TRANSFERÊNCIA DE TURNO E TURMA - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "191",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",                      
                    }
                },

                {
                    typeAttendanceId: "E5-11", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / NEGOCIAÇÃO DE PARCELAS EM ATRASO */
                    data: {                          

                        respAttendantId: "4545", //DRC
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00019",                       
                        subject: "SOLICITAÇÃO DE ALUNO: NEGOCIAÇÃO DE PARCELAS EM ATRASO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "75",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",                      
                    }
                },

                {
                    typeAttendanceId: "E5-12", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TRANCAMENTO GRADUAÇÃO */
                    data: {                          

                        respAttendantId: "4288", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00057",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TRANCAMENTO GRADUAÇÃO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "193",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",                      
                    }
                },

                {
                    typeAttendanceId: "E5-13", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TRANCAMENTO CURSOS LIVRES */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00057",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TRANCAMENTO CURSOS LIVRES - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "194",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",                
                    }
                },

                {
                    typeAttendanceId: "E5-14", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / TROCA DE CURSO */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00043",                       
                        subject: "SOLICITAÇÃO DE ALUNO: TROCA DE CURSO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "121",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",
                        priority: "",             
                    }
                },

                {
                    typeAttendanceId: "E5-15", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / FORMATURA GABINETE CURSOS LIVRES */
                    data: {                          

                        respAttendantId: "5125", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "SOLICITAÇÃO DE ALUNO: FORMATURA GABINETE CURSOS LIVRES - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "197",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",          
                    }
                },

                {
                    typeAttendanceId: "E5-16", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / FORMATURA SOLENE CURSOS LIVRES */
                    data: {                          

                        respAttendantId: "5125", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "SOLICITAÇÃO DE ALUNO: FORMATURA SOLENE CURSOS LIVRES - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "198",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",          
                    }
                },

                {
                    typeAttendanceId: "E5-17", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / FORMATURA GABINETE CURSOS TÉCNICOS */
                    data: {                          

                        respAttendantId: "5125", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "SOLICITAÇÃO DE ALUNO: FORMATURA GABINETE CURSOS TÉCNICOS - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "200",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",    
                    }
                },

                {
                    typeAttendanceId: "E5-18", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / FORMATURA SOLENE CURSOS TÉCNICOS */
                    data: {                          

                        respAttendantId: "5125", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "SOLICITAÇÃO DE ALUNO: FORMATURA SOLENE CURSOS TÉCNICOS - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "201",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",          
                    }
                },

                {
                    typeAttendanceId: "E5-19", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / ATENDIMENTO NAAD */
                    data: {                          

                        respAttendantId: "5275", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00090",                       
                        subject: "SOLICITAÇÃO DE ALUNO: NAAD - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "208",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",          
                    }
                },

                {
                    typeAttendanceId: "E5-20", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / HISTÓRICO */
                    data: {                          

                        respAttendantId: "4330", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00009",  
                        subject: "SOLICITAÇÃO DE ALUNO: HISTÓRICO - ATENDIMENTO INTRANET",
                        accessLevel: null,
                        typeAttendanceId: "236",
                        groupAttendanceId: "25",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "",       
                    }
                },

                {
                    typeAttendanceId: "E5-21", /* EDUCACIONAL / SOLICITAÇÃO DE ALUNOS / EMENTA */
                    data: {                          

                        respAttendantId: "4330", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00141",  
                        subject: "SOLICITAÇÃO DE ALUNO: EMENTA - ATENDIMENTO INTRANET",
                        accessLevel: "0",
                        typeAttendanceId: "260",
                        groupAttendanceId: "17",
                        personId: "",
                        requestText: "",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",     
                    }
                },

                {
                    typeAttendanceId: "E6", /* EDUCACIONAL / AJUSTE DIÁRIO / AJUSTE DE FREQUÊNCIA */
                    data: {                          

                        respAttendantId: "3658", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00037",  
                        subject: "AJUSTE DE FREQUÊNCIA",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",                        
                        clientType: "Aluno",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E6-2", /* EDUCACIONAL / AJUSTE DIÁRIO / AJUSTE DE NOTA */
                    data: {                          

                        respAttendantId: "3658", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00038",  
                        subject: "AJUSTE DE NOTA",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "", 
                        clientType: "Aluno",
                        attendantId: "",      
                    }
                },

                {
                    typeAttendanceId: "E6-3", /* EDUCACIONAL / AJUSTE DIÁRIO / CONTEÚDO PROGRAMÁTICO */
                    data: {                          

                        respAttendantId: "3658", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00039",  
                        subject: "CONTEÚDO PROGRAMÁTICO",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",       
                        clientType: "Aluno",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "E6-4", /* EDUCACIONAL / AJUSTE DIÁRIO / RETORNO TRANCAMENTO/LIBERAÇÃO */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00045",  
                        subject: "RETORNO DE TRANCAMENTO / LIBERAÇÃO",
                        accessLevel: "0",
                        typeAttendanceId: "168",
                        groupAttendanceId: "17",
                        personId: "",
                        requestText: "", 
                        clientType: "Aluno",
                        attendantId: "",      
                    }
                },

                {
                    typeAttendanceId: "E6-5", /* EDUCACIONAL / AJUSTE DIÁRIO / TRANCAMENTO */
                    data: {                          

                        respAttendantId: "1933", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00057",  
                        subject: "TRANCAMENTO",
                        accessLevel: "0",
                        typeAttendanceId: "194",
                        groupAttendanceId: "17",
                        personId: "",
                        requestText: "",  
                        clientType: "Aluno",
                        attendantId: "",     
                    }
                },

                {
                    typeAttendanceId: "E6-6", /* EDUCACIONAL / AJUSTE DIÁRIO / TRANSFERÊNCIA/TRANSFERÊNCIA RETROATIVA */
                    data: {                          

                        respAttendantId: "3658", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00044",  
                        subject: "TRANSFERÊNCIA / TRANSFERÊNCIA RETROATIVA",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",   
                        clientType: "Aluno",
                        attendantId: "",    
                    }
                },
                {
                    typeAttendanceId: "E6-7", /* EDUCACIONAL / AJUSTE DIÁRIO / INCLUIR/RETIRAR STATUS (LETRAS) */
                    data: {                          

                        respAttendantId: "3658", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00046",  
                        subject: "INCLUIR / RETIRAR STATUS (LETRAS)",
                        accessLevel: "0",
                        typeAttendanceId: "50",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",   
                        clientType: "Aluno",
                        attendantId: "",    
                    }
                },
                {
                    typeAttendanceId: "E7", /* EDUCACIONAL / PRODUÇÃO DE MATERIAIS */
                    data: {                          

                        respAttendantId: "4792", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00114",  
                        subject: "SOLICITAÇÃO DE PRODUÇÃO DE MATERIAIS",
                        accessLevel: "0",
                        typeAttendanceId: "225",
                        groupAttendanceId: "10",
                        personId: "",
                        requestText: "",  
                        clientType: "Pessoa",
                        attendantId: "",     
                    }
                },

            ]    
    }

    getDataByAttendanceId(typeAttendanceId){
        for (let i=0; i<this.instance.length; i++){
            if(this.instance[i].typeAttendanceId == typeAttendanceId)
                return this.instance[i];
        }
    }
}