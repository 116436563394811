<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }" class="validator">
      <div class="subContainer">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'business-time']" />
            <h2 class="tituloAtendimento">
               Solicitação de Reembolso
            </h2>
          </div>
        </vs-row>

        <div> 
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Valor Total <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Valor total"
                  rules="required"
                   
                >
                  <vs-input
                    v-model="amount"
                    placeholder="Digite o Valor Total"
                    class="selectExample"
                    type= "number"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-w="12">
              <fieldset>
                <legend>Dados bancarios <b style="color: red;">*</b></legend>
                <fieldset class="fieldsetColor">
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">Nome Completo <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Nome"
                      rules="required"
                       
                    >
                      <vs-input
                        v-model="name"
                        placeholder="Digite o Nome Completo"
                        class="selectExampleDouble"
                      />
                       
                    </ValidationProvider>
                  </vs-col>

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">CPF <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="CPF"
                      rules="required"
                       
                    >
                      <vs-input
                        v-mask="'###.###.###-##'"
                        v-model="cpf"
                        placeholder="000.000.000-00"
                        class="selectExampleDouble"
                      />
                       
                    </ValidationProvider>
                  </vs-col>
                </fieldset>

                <fieldset class="fieldsetColor">
                  <vs-col  vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">Banco <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Banco"
                      rules="required"
                       
                    >
                      <vs-input v-model="bank" placeholder="Digite o Banco" class="selectExampleDouble" />
                       
                    </ValidationProvider>
                  </vs-col>

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">Operação <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Operação"
                      rules="required"
                       
                    >
                      <vs-input
                        v-mask="'###'"
                        v-model="operation"
                        placeholder="Digite a Opereção"
                        class="selectExampleDouble"
                      />
                       
                    </ValidationProvider>
                  </vs-col>
                </fieldset>
                
                <fieldset class="fieldsetColor">
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">Agência <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Agencia"
                      rules="required"
                       
                    >
                      <vs-input
                        v-mask="'####'"
                        v-model="agency"
                        placeholder="Digite a Agência"
                        class="selectExampleDouble"
                      />

                       
                    </ValidationProvider>
                  </vs-col>

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend class="legendBancario">Conta <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Conta"
                      rules="required"
                       
                    >
                      <vs-input
                        v-mask="'########-#'"
                        v-model="account"
                        placeholder="Digite a Conta"
                        class="selectExampleDouble"
                      />
                       
                    </ValidationProvider>
                  </vs-col>
                </fieldset>  
              </fieldset>          
            </vs-col>          
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Anexo <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Anexo"
                  ref="provider"
                  rules="required"
                   
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                   
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Texto Solicitação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  rows="11"
                  label="Texto Solicitação *"
                  class="textArea"
                  height="15em"
                  counter="500"
                  v-model="solicitation"
                />
                 
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid || !visibleButton"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
        </div>        
      </div>            
    </ValidationObserver>
  </section>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";

export default {
  name: "SolicitaçãoDeReembolso",

  data: () => ({
    files: null,
    base64Image: null,
    solicitation: null,
    name: null,
    cpf: null,
    agency: null,
    account: null,
    bank: null,
    idAttendance: null,
    errorIdAttendance: null,
    operation: null,
    amount: null,
    visibleButton: false,
  }),
  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      });
  },

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A10-3');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.subject = "NUMERÁRIOS - SOLICITAÇÕES DE REEMBOLSO";
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nVALOR TOTAL: ${this.amount}\nNOME COMPLETO: ${this.name}\nCPF: ${this.cpf}\nBANCO: ${this.bank}\nOPERAÇÃO: ${this.operation}\nAGÊNCIA: ${this.agency}\nCONTA: ${this.account}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          } 
          this.$vs.loading.close();
        })
      this.resetForm();
    },
    resetForm() {
      this.solicitation = null;
      this.amount = null;
      this.name = null;
      this.cpf = null;
      this.bank = null;
      this.operation = null;
      this.agency = null;
      this.account = null;
      this.visibleButton = false;
      this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.fieldsetColor {
  border: 1px solid white;
}

.legendBancario {    
  padding-left: 1em;
}
</style>