<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'cubes']" />
              <h2 class="tituloAtendimento">Cubos</h2>
            </div>
          </vs-row>

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message
                  class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"
                />
              </vs-col>
            </vs-row>
          </div>

          <div>
            <vs-row class="pd-t">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>
                    Tipo de Solicitação <b style="color: red">*</b>
                  </legend>
                  <vs-select
                    class="selectExampleDouble"
                    placeholder="Selecione o Tipo de Ajuste"
                    v-model="selectType"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in type"
                    />
                  </vs-select>
                </fieldset>
              </vs-col>
            </vs-row>
          </div>
          <div v-if="selectType != null">
            <vs-row class="pd-t" v-if="selectType == 'Ajuste de Cubo'">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>Cubos <b style="color: red">*</b></legend>
                  <ValidationProvider name="Cubos" rules="required">
                    <vs-select
                      autocomplete
                      @input-change="inputChange"
                      class="selectExample"
                      v-model="selectedCube"
                      placeholder="Selecione um Cubo"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.NOME"
                        :text="item.NOME"
                        v-for="(item, index) in cubes"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Possui Senha? <b style="color: red">*</b></legend>
                  <vs-select
                    class="selectExampleDouble"
                    placeholder="Selecione uma opção"
                    v-model="selectOption"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in password"
                    />
                  </vs-select>
                </fieldset>
              </vs-col>
              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>
                    Quem irá acessar o cubo? <b style="color: red">*</b>
                  </legend>
                  <vs-input
                    v-model="responsible"
                    type="text"
                    class="selectExampleDouble"
                  />
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Anexo (Opcional)</legend>
                  <ValidationProvider name="Anexo" ref="provider">
                    <input
                      class="selectExample"
                      ref="image"
                      type="file"
                      @change="onFileUpload"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t" v-if="selectType == 'Criação de Cubo'">
              <vs-col>
                <ValidationProvider
                  name="Dados do Cubo"
                  rules="required|max: 300"
                >
                  <vs-textarea
                    rows="8"
                    label="Dados que o cubo deve trazer"
                    class="textArea"
                    height="8em"
                    counter="300"
                    v-model="describeInformations"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                >
                  <vs-textarea
                    rows="11"
                    label="Descreva sua Solicitação"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="describeRequest"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>

                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Units from "../../../services/units";
import Attendance from "../../../services/attendance";
import ListOfCubes from "../../../services/management";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import AdministrativoModel from "../../../models/administrativo/administrativo";

export default {
  name: "Cubos",
  data: () => ({
    files: "",
    base64Image: "",
    describeRequest: null,
    describeInformations: null,
    selectedCube: null,
    cubes: null,
    idAttendance: null,
    errorIdAttendance: null,
    selectType: null,
    type: [
      { text: "Ajuste de Cubo", value: "Ajuste de Cubo" },
      { text: "Criação de Cubo", value: "Criação de Cubo" },
    ],
    selectOption: null,
    password: [
      { text: "Sim", value: "Sim" },
      { text: "Não", value: "Não" },
    ],
    responsible: null,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });

    new ListOfCubes()
      .getListOfCubes()
      .then(({ data }) => {
        this.cubes = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });
  },

  methods: {
    async onFileUpload(event) {
      this.files = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.files);
      if (valid) {
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    inputChange(event) {
      return event.target.value;
    },

    async createAttendance() {
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload1 = new AdministrativoModel().getDataByAttendanceId("A13-4");

      payload1.data.locationId = personLocalId.toString();
      payload1.data.attendantId = attendantId.toString();
      payload1.data.attachment = this.base64Image;
      payload1.data.fileName = this.files.name;
      payload1.data.personId = personId.toString();
      payload1.data.subject = "AJUSTE DE CUBO";
      payload1.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCUBO: ${this.selectedCube}\nPOSSUI SENHA: ${this.selectOption}\nQUEM PODE TER ACESSO: ${this.responsible}\nSOLICITAÇÃO: ${this.describeRequest}`;

      const payload2 = new AdministrativoModel().getDataByAttendanceId("A13-4");

      payload2.data.locationId = personLocalId.toString();
      payload2.data.attendantId = attendantId.toString();
      payload2.data.attachment = this.base64Image;
      payload2.data.fileName = this.files.name;
      payload2.data.personId = personId.toString();
      payload2.data.subject = "CRIAÇÃO DE CUBO";
      payload2.data.requestText = `USUÁRIO: ${personName} - ${personId}\nPOSSUI SENHA: ${this.selectOption}\nQUEM PODE TER ACESSO: ${this.responsible}\nINFORMAÇÕES NECESSÁRIAS: ${this.describeInformations}\nSOLICITAÇÃO: ${this.describeRequest}`;

      let payloads =
        this.selectType == "Ajuste de Cubo" ? payload1.data : payload2.data;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payloads)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    resetForm() {
      (this.describeRequest = null),
        (this.selectedCube = null),
        (this.files = null),
        (this.base64Image = null),
        (this.describeInformations = null),
        (this.selectType = null),
        (this.selectOption = null),
        (this.responsible = null),
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
